import axios from 'axios';
import api_link from '../../config/variables.js';

export default {
  namespaced: true,
  state() {
    return {
      callId: null,
      isCopy: 0,
      newsletters: [],
      receivers: [],
    }
  },
  mutations: {
    callEditNewsletter(state, payload) {
      state.callId = payload.callId;
      state.isCopy = payload.isCopy;
    },
    getNewsletters(state, payload) {
      state.newsletters = payload;
    },
    getReceivers(state, payload) {
      state.receivers = payload;
    },
    addNewsletter(state, payload) {
      state.newsletters.push(payload);
      state.callId = payload.newsletterId;
    },
  },
  actions: {
    parseNewsletterId(context, payload) {
      const toEditId = {
        callId: payload.id,
        isCopy: payload.isCopy
      };
      context.commit('callEditNewsletter', toEditId);
    },
    async getNewsletters(context) {
      const token = this.getters["auth/token"];
      const newsletters = await fetch(api_link + '/newsletters', {
        method: 'GET',
        headers: {
          'Authorization': 'Bearer ' + token.token,
          'Content-Type': 'application/json',
        },
      });
      const responseNewsletters = await newsletters.json();
      if (!newsletters.ok) {
        const error = new Error(responseNewsletters.message || 'Newsletter konnten nicht geladen werden!');
        throw error;
      }

      const letters = [];
      for (const key in responseNewsletters) {
        var releaseValue = null;
        var sentValue = null;
        if (responseNewsletters[key].release) {
          releaseValue = 'Ja';
        }
        else {
          releaseValue = 'Nein';
        }
        if (responseNewsletters[key].sent) {
          sentValue = 'Ja';
        }
        else {
          sentValue = 'Nein';
        }

        const letter = {
          newsletterId: responseNewsletters[key].newsletterId,
          templateId: responseNewsletters[key].templateId,
          name: responseNewsletters[key].name,
          senderName: responseNewsletters[key].senderName,
          senderEmail: responseNewsletters[key].senderEmail,
          html: responseNewsletters[key].html,
          attachmentFile: responseNewsletters[key].attachmentFile,
          releaseAt: responseNewsletters[key].releaseAt,
          release: releaseValue,
          sent: sentValue,
          hasReceivers: responseNewsletters[key].hasReceivers
        };
        letters.push(letter);
      }
      context.commit('getNewsletters', letters);
    },
    async newsletterReceiver(context, payload) {
      const token = this.getters["auth/token"];
      const newsletterReceiver = await fetch(api_link + '/newsletters/' + payload.id + '/receivers', {
        method: 'GET',
        headers: {
          'Authorization': 'Bearer ' + token.token,
          'Content-Type': 'application/json',
        }
      });

      const responseReceiver = await newsletterReceiver.json();
      const receivers = [];
      for (const key in responseReceiver) {
        var verifiedValue = null;
        if (responseReceiver[key].sent) {
          verifiedValue = 'Ja';
        }
        else {
          verifiedValue = 'Nein';
        }
        const receiver = {
          newsletterId: responseReceiver[key].newsletterId,
          newsletterName: responseReceiver[key].newsletterName,
          subscriberId: responseReceiver[key].subscriberId,
          salutation: responseReceiver[key].salutation,
          firstName: responseReceiver[key].firstName,
          lastName: responseReceiver[key].lastName,
          email: responseReceiver[key].email,
          sendAt: responseReceiver[key].sendAt,
          sent: verifiedValue
        };
        receivers.push(receiver);
      }
      context.commit('getReceivers', receivers);
    },
    async newNewsletter(_, payload) {
      const token = this.getters["auth/token"];
      // Rename File
      var blob = payload.file.slice(0, payload.file.size, payload.file.type);
      var newFile = new File([blob], payload.attachmentFile, { type: payload.file.type });
      // File Upload
      if (payload.file.length !== 0) {
        const formData = new FormData();
        formData.append('uploadFile', newFile);
        const fileUpload = await axios.post(api_link + '/files', formData, {
          method: 'POST',
          headers: {
            'Authorization': 'Bearer ' + token.token,
            'Content-Type': 'multipart/form-data',
          },
        }).catch(function () {
          const responsefileUpload = fileUpload.json();
          const error = new Error(responsefileUpload.message || 'Datei konnte nicht gespeichert!');
          throw error;
        });
      }

      const respones = await fetch(api_link + '/newsletters', {
        method: 'POST',
        headers: {
          'Authorization': 'Bearer ' + token.token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          attachmentFile: payload.attachmentFile,
          html: payload.html,
          name: payload.name,
          release: payload.release,
          releaseAt: payload.releaseAt,
          releaseDate: payload.releaseDate,
          releaseTimestamp: payload.releaseTimestamp,
          senderEmail: payload.senderEmail,
          senderName: payload.senderName,
        }),
      });

      if (!respones.ok) {
        const responseNewsletter = respones.json();
        const error = new Error(responseNewsletter.message || 'Es wurde kein Newsletter angelegt!');
        throw error;
      }
    },
    async editNewsletter(_, payload) {
      const token = this.getters["auth/token"];
      // Rename File
      var blob = payload.file.slice(0, payload.file.size, payload.file.type);
      var newFile = new File([blob], payload.attachmentFile, { type: payload.file.type });
      // File Upload
      if (payload.file.length !== 0) {
        const formData = new FormData();
        formData.append('uploadFile', newFile);
        const fileUpload = await axios.post(api_link + '/files', formData, {
          method: 'POST',
          headers: {
            'Authorization': 'Bearer ' + token.token,
            'Content-Type': 'multipart/form-data',
          },
        }).catch(function () {
          const responsefileUpload = fileUpload.json();
          const error = new Error(responsefileUpload.message || 'Datei konnte nicht gespeichert!');
          throw error;
        });
      }

      await fetch(api_link + '/newsletters/' + payload.newsletterId, {
        method: 'PUT',
        headers: {
          'Authorization': 'Bearer ' + token.token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          attachmentFile: payload.attachmentFile,
          hasReceivers: payload.hasReceivers,
          html: payload.html,
          name: payload.name,
          newsletterId: payload.newsletterId,
          release: payload.release,
          releaseAt: payload.releaseAt,
          releaseAtDate: payload.releaseAtDate,
          releaseAtTimestamp: payload.releaseAtTimestamp,
          senderEmail: payload.senderEmail,
          senderName: payload.senderName,
          sent: payload.sent,
          templateId: payload.templateId,
        }),
      });
    },
    async copieNewsletter(_, payload) {
      const token = this.getters["auth/token"];
      // Rename File
      var blob = payload.file.slice(0, payload.file.size, payload.file.type);
      var newFile = new File([blob], payload.attachmentFile, { type: payload.file.type });
      // File Upload
      if (payload.file.length !== 0) {
        const formData = new FormData();
        formData.append('uploadFile', newFile);
        const fileUpload = await axios.post(api_link + '/files', formData, {
          method: 'POST',
          headers: {
            'Authorization': 'Bearer ' + token.token,
            'Content-Type': 'multipart/form-data',
          },
        }).then(function () {
          console.log('SUCCESS!!');
        }).catch(function () {
          const responsefileUpload = fileUpload.json();
          const error = new Error(responsefileUpload.message || 'Datei konnte nicht gespeichert!');
          throw error;
        });
      }

      await fetch(api_link + '/newsletters', {
        method: 'POST',
        headers: {
          'Authorization': 'Bearer ' + token.token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          attachmentFile: payload.attachmentFile,
          hasReceivers: payload.hasReceivers,
          html: payload.html,
          name: payload.name,
          release: payload.release,
          releaseAt: payload.releaseAt,
          releaseAtDate: payload.releaseAtDate,
          releaseAtTimestamp: payload.releaseAtTimestamp,
          senderEmail: payload.senderEmail,
          senderName: payload.senderName,
          sent: payload.sent,
          templateId: payload.templateId,
        }),
      });

    },
    async deleteNewsletter(_, state) {
      const token = this.getters["auth/token"];
      await fetch(api_link + '/newsletters/' + state.id, {
        method: 'DELETE',
        headers: {
          'Authorization': 'Bearer ' + token.token,
          'Content-Type': 'application/json',
        }
      });
    },
    async addNewsletter(context, data) {
      const NewNewsletterData = {
        name: data.name,
        senderName: data.senderName,
        senderEmail: data.senderEmail,
        html: data.html,
        releaseAt: data.releaseAt
      };
      context.commit('addNewsletter', {
        ...NewNewsletterData,
        newsletterId: 1
      });
    },
    async selectedSendNewsletters(_, payload) {
      const token = this.getters["auth/token"];
      await fetch(api_link + '/newsletters/' + payload.id + '/send', {
        method: 'GET',
        headers: {
          'Authorization': 'Bearer ' + token.token,
          'Content-Type': 'application/json',
        }
      });
    },
    async selectedSendTestNewsletters(_, payload) {
      const token = this.getters["auth/token"];
      await fetch(api_link + '/newsletters/' + payload.id + '/send?test-email=' + payload.email, {
        method: 'GET',
        headers: {
          'Authorization': 'Bearer ' + token.token,
          'Content-Type': 'application/json',
        }
      });
    },
    async downloadFile(_, payload) {
      const token = this.getters["auth/token"];
      await axios.get(api_link + '/newsletters/' + payload.id + '/download-file', {
        headers: {
          'Authorization': 'Bearer ' + token.token,
          'Content-Type': 'application/json',
        },
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', payload.attachmentFile);
        document.body.appendChild(link);
        link.click();
      })
    }
  },
  getters: {
    newsletters(state) {
      return state.newsletters;
    },
    receivers(state) {
      return state.receivers;
    },
    callEditNewsletter(state) {
      return state.newsletters.find(letter => letter.newsletterId === state.callId);
    },
    callCopyNewsletter(state) {
      return state.isCopy;
    }
  }
};