import api_link from '../../config/variables.js';

export default {
  namespaced: true,
  state() {
    return {
      subscribers: [],
      unsubReasons: [],
      safeUnsubReason: null,
      salutations: null,
    }
  },
  mutations: {
    getSubscribers(state, payload) {
      state.subscribers = payload;
    },
    getUnsubReasons(state, payload) {
      state.unsubReasons = payload;
    },
    safeUnsubReason(state, payload) {
      state.safeUnsubReason = payload;
    },
    salutations(state, payload) {
      state.salutations = payload;
    }
  },
  actions: {
    async salutations(context) {
      const response = await fetch(api_link + '/salutations', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
      });
      const responseSalutation = await response.json();
      context.commit('salutations', responseSalutation);
    },
    async registerSubscribe(_, payload) {
      const response = await fetch(api_link + '/subscribers', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          accept: payload.accept,
          email: payload.email,
          firstName: payload.firstName,
          lastName: payload.lastName,
          recaptcha: payload.recaptcha,
          salutationId: payload.salutationId,
        }),
      });
      if (!response.ok) {
        const responseRegisterSubscribe = response.json()
        const error = new Error(responseRegisterSubscribe.message || 'Abonnent konnte nicht gespeichert werden!');
        throw error;
      }
    },
    async verifyEmail(_, payload) {
      await fetch(api_link + '/subscribers/' + payload.guid, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          verified: payload.verified
        }),
      });
    },
    async safeUnsubReason(context, state) {
      const safeUnsubReason = await fetch(api_link + '/subscribers/' + state.guid + '?reason=' + state.reason, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        }
      });

      if (!safeUnsubReason.ok) {
        //Da bei einer erfolgreichen Übertragung von (safeTemplate) kein Response zurück kommt, kann nur bei einer Fehlermeldung der Response ausgewertet werden
        const responseTemplates = await safeUnsubReason.json();
        const error = new Error(responseTemplates.message || 'Failed to fetch!');
        throw error;
      }
      if (safeUnsubReason['status'] === 204) {
        const message = {
          title: 'Abgemeldet',
          text: 'Erfolgreich vom Newsletter abgemeldet'
        };
        context.commit('safeUnsubReason', message);
      }
    },
    deleteSubscriber(_, state) {
      const token = this.getters["auth/token"];
      fetch(api_link + '/subscribers/' + state.id + '/delete-perm', {
        method: 'POST',
        headers: {
          'Authorization': 'Bearer ' + token.token,
          'Content-Type': 'application/json',
        }
      });
    },
    async getUnsubReasons(context) {
      const unsubReasons = await fetch(api_link + '/unsub-reasons', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const responseUnsubReasons = await unsubReasons.json();

      if (!unsubReasons.ok) {
        const error = new Error(responseUnsubReasons.message || 'Abmeldegründe konnten nicht geladen werden!');
        throw error;
      }
      const reasons = [];
      for (const key in responseUnsubReasons) {
        const reason = {
          unsubReasonId: responseUnsubReasons[key].unsubReasonId,
          text: responseUnsubReasons[key].text,
        };
        reasons.push(reason);
      }
      context.commit('getUnsubReasons', reasons);
    },
    async getSubscribers(context, payload) {
      if (!payload.forceRefresh && !context.getters.handleError) {
        return;
      }

      const token = this.getters["auth/token"];
      const subscribers = await fetch(api_link + '/subscribers', {
        method: 'GET',
        headers: {
          'Authorization': 'Bearer ' + token.token,
          'Content-Type': 'application/json',
        },
      });
      const responseSubscribers = await subscribers.json();

      if (!subscribers.ok) {
        const error = new Error(responseSubscribers.message || 'Abonnent konnte nicht gelöscht werden');
        throw error;
      }

      const subs = [];
      for (const key in responseSubscribers) {
        var verifiedValue = null;
        var archivedValue = null;
        if (responseSubscribers[key].verified) {
          verifiedValue = 'Ja';
        }
        else {
          verifiedValue = 'Nein';
        }
        if (!responseSubscribers[key].archived) {
          archivedValue = 'Ja';
        }
        else {
          archivedValue = 'Nein';
        }
        const sub = {
          subscriberId: responseSubscribers[key].subscriberId,
          salutation: responseSubscribers[key].salutation,
          firstName: responseSubscribers[key].firstName,
          lastName: responseSubscribers[key].lastName,
          email: responseSubscribers[key].email,
          unsubReason: responseSubscribers[key].unsubReason,
          subscribedAt: responseSubscribers[key].subscribedAt,
          verified: verifiedValue,
          archived: archivedValue,
        };
        subs.push(sub);
      }
      context.commit('getSubscribers', subs);
    }
  },
  getters: {
    subscribers(state) {
      return state.subscribers;
    },
    unsubReason(state) {
      return state.unsubReasons;
    },
    safeUnsubReason(state) {
      return state.safeUnsubReason;
    },
    salutations(state) {
      return state.salutations
    }
  }
};