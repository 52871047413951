<template>
  <div>
    <custom-message
      :show="!!error"
      mode="error"
      title="Fehlermeldung"
      :decline="decline"
      @close="errorMessage"
    >
      <p>{{ error }}</p>
    </custom-message>
    <custom-message :show="!!message" @close="handleMessage">
      <template v-slot:header>
        <p>{{ title }}</p>
      </template>
      <p>{{ message }}</p>
    </custom-message>
    <div>
      <h3 class="page-title pt-3">Newsletter abbestellen</h3>
      <form style="padding: 1rem" @submit.prevent="safeUnsubReason">
        <fieldset>
          <div class="row mb-3 align-items-center">
            <div class="col-12 col-md-2">
              <label class="form-label float-md-end">Grund</label>
            </div>
            <div class="col-auto">
              <input
                class="form-control data-field"
                type="text"
                list="ReasonOptions"
                v-model="selected"
              />
            </div>
            <datalist id="ReasonOptions">
              <option
                v-for="reason in ReasonOptions"
                :key="reason.unsubReasonId"
                :value="reason.text"
              >
                {{ reason.text }}
              </option>
            </datalist>
          </div>
          <div class="row align-items-center">
            <div class="col-12 col-md-2"></div>
            <div class="col-auto">
              <button class="btn button-control" :disabled="!selected">
                Abbestellen
              </button>
            </div>
          </div>
        </fieldset>
      </form>
    </div>
  </div>
</template>

<script>
import CustomMessage from '../../components/ui/CustomMessage.vue';
export default {
  components: {
    CustomMessage,
  },
  data() {
    return {
      error: null,
      decline: null,
      title: '',
      message: null,
      ReasonOptions: [],
      selected: null,
    };
  },
  methods: {
    async getUnsubReasons() {
      this.ReasonOptions = await this.$store.getters['subs/unsubReason'];
    },
    async safeUnsubReason() {
      try {
        await this.$store.dispatch('subs/safeUnsubReason', {
          guid: this.$route.params.subguid,
          reason: this.selected,
        });
        this.title = this.$store.getters['subs/safeUnsubReason']['title'];
        this.message = this.$store.getters['subs/safeUnsubReason']['text'];
      } catch (error) {
        this.error = error.message || 'Something went wrong!';
        this.decline = 'Schließen';
      }
    },
    handleMessage() {
      this.message = null;
    },
    errorMessage() {
      this.error = null;
    },
  },
  async created() {
    try {
      await this.$store.dispatch('subs/getUnsubReasons');
    } catch (error) {
      this.error = error.message || 'Something went wrong!';
      this.decline = 'Schließen';
    }
    this.getUnsubReasons();
  },
};
</script>

<style scoped>
</style>