import { createStore } from 'vuex';
import createPersistedState from "vuex-persistedstate";

import newslettersModule from './modules/newsletters.js';
import subscribersModule from './modules/subscribers.js';
import authenticateModule from './modules/authenticate.js';
import templatesModule from './modules/templates.js';

const store = createStore({
  modules: {
    letters: newslettersModule,
    subs   : subscribersModule,
    auth   : authenticateModule,
    temp   : templatesModule
  },
  plugins: [createPersistedState()],
});

export default store;