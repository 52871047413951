<template>
  <p>
    Die Überprüfung der EMail war erfolgreich. Damit ist die Anmeldung abgeschlossen. Vielen Dank für die Anmeldung zu unserem Newsletter.
  </p>
</template>

<script>
export default {
  async created() {
      await this.$store.dispatch('subs/verifyEmail', {
        guid: this.$route.query.guid,
        verified: true
      });
    }
  }
</script>