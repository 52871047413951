<template>
  <error-message :show="!!error" title="Fehlermeldung" @close="errorMessage">
    <p>{{ error }}</p>
  </error-message>
  <custom-message :show="!!message" @close="handleMessage" :decline="decline">
    <template v-slot:header>
      <p>{{ title }}</p>
    </template>
    <p>{{ message }}</p>
  </custom-message>
  <the-header></the-header>
  <div>
    <h3 class="page-title">{{ templates.name }} bearbeiten</h3>
    <form @submit.prevent>
      <fieldset>
        <div class="mt-3">
          <label class="form-label">Html</label>
          <textarea
            class="form-control data-field data-field-textarea"
            name="html"
            v-model="templates.html"
            required
          ></textarea>
        </div>
        <div class="mt-3">
          <label class="form-label">Abmelde-Text</label>
          <input
            class="form-control data-field"
            type="text"
            v-model="templates.unsubText"
            required
          />
        </div>
        <div class="mt-3">
          <label class="form-label">Abmelde-Url</label>
          <input
            class="form-control data-field"
            type="text"
            v-model="templates.unsubUrl"
            required
          />
        </div>
        <div class="mt-3">
          <button
            class="btn button-control"
            :disabled="
              !templates.html || !templates.unsubText || !templates.unsubUrl
            "
            @click="safeTemplates"
          >
            Speichern
          </button>
        </div>
      </fieldset>
    </form>
  </div>
</template>


<script>
import TheHeader from '../../components/layout/TheHeader.vue';
import CustomMessage from '../../components/ui/CustomMessage.vue';

export default {
  components: {
    TheHeader,
    CustomMessage,
  },
  data() {
    return {
      error: null,
      decline: null,
      message: null,
    };
  },
  methods: {
    async getTemplates() {
      try {
        await this.$store.dispatch('temp/getTemplates');
      } catch (error) {
        this.error = error.message || 'Something went wrong!';
      }
    },
    async safeTemplates() {
      try {
        await this.$store.dispatch('temp/safeTemplates', {
          html: this.templates.html,
          unsubText: this.templates.unsubText,
          unsubUrl: this.templates.unsubUrl,
        });
        this.title = this.$store.getters['temp/safeTemplates']['title'];
        this.message = this.$store.getters['temp/safeTemplates']['text'];
        this.decline = 'Schließen';
      } catch (error) {
        this.error = error.message || 'Something went wrong!';
        this.decline = 'Schließen';
      }
    },
    handleMessage() {
      this.message = null;
    },
    errorMessage() {
      this.error = null;
    },
  },
  computed: {
    templates() {
      return this.$store.getters['temp/templates'];
    },
  },
  beforeMount() {
    this.getTemplates();
  },
};
</script>



<style scoped>
form {
  display: block;
  margin-top: 0em;
  padding: 1rem;
}

fieldset {
  display: block;
  margin-inline-start: 2px;
  margin-inline-end: 2px;
  padding-block-start: 0.35em;
  padding-inline-start: 0.75em;
  padding-inline-end: 0.75em;
  padding-block-end: 0.625em;
  min-inline-size: min-content;
}

.data-field-textarea {
  min-width: 50%;
  height: 350px;
}
@media (min-width: 992px) {
  .data-field {
    min-width: 50%;
  }
}
</style>
