<template>
  <custom-message
    :show="!!error"
    mode="error"
    title="Fehlermeldung"
    :decline="decline"
    @close="errorMessage"
  >
    <p>{{ error }}</p>
  </custom-message>
  <the-header></the-header>
  <h3 class="grid-title">Empfänger</h3>
  <DxDataGrid
    id="dataGrid"
    :data-source="receivers"
    key-expr="subscriberId"
    :show-borders="config.Datagrid.showBorders"
    :allowColumnReordering="config.Datagrid.allowColumnReordering"
    :allowColumnResizing="config.Datagrid.allowColumnResizing"
    :columnAutoWidth="config.Datagrid.columnAutoWidth"
    :column-hiding-enabled="config.Datagrid.columnHidingEnabled"
    :focused-row-enabled="config.Datagrid.focusedRowEnabled"
    :selection="config.Datagrid.selection"
  >
    <DxPaging :page-size="config.Paging.pageSize" />
    <DxPager
      :visible="config.Pager.visible"
      :display-mode="config.Pager.displayMode"
      :show-page-size-selector="config.Pager.showPageSizeSelector"
      :show-navigation-buttons="config.Pager.showNavigationButtons"
    />
    <DxColumnChooser
      :enabled="config.ColumnChooser.enabled"
      :mode="config.ColumnChooser.mode"
      :allowSearch="config.ColumnChooser.allowSearch"
      :width="config.ColumnChooser.width"
      :height="config.ColumnChooser.height"
    />
    <DxColumn data-type="string" data-field="subscriberId" caption="ID" />
    <DxColumn data-field="salutation" caption="Anrede" />
    <DxColumn data-field="firstName" caption="Vorname" />
    <DxColumn data-field="lastName" caption="Nachname" />
    <DxColumn
      data-field="email"
      caption="E-Mail Adresse"
      cell-template="emailLink"
    />
    <DxColumn data-field="attachmentFile" caption="Anhang" />
    <DxColumn
      data-field="sendAt"
      data-type="date"
      format="dd.MM.yyyy HH:mm:ss"
      caption="Sende-Zeitpunkt"
    />
    <DxColumn data-field="sent" caption="Gesendet" />
    <template #emailLink="{ data }">
      <a :href="'mailto:' + data.value">{{ data.value }}</a>
    </template>
    <DxSearchPanel :visible="config.SearchPanel.visible" />
    <DxLoadPanel :enabled="config.LoadPanel.enabled" />
    <DxHeaderFilter :visible="config.HeaderFilter.enabled" />
    <DxFilterRow :visible="config.FilterRow.visible" />
  </DxDataGrid>
</template>

<script>
import deMessages from '../../locales/de.json';
import { locale, loadMessages } from 'devextreme/localization';
import TheHeader from '../../components/layout/TheHeader.vue';
import config from '../../config/Datagrid.json';
import {
  DxDataGrid,
  DxLoadPanel,
  DxColumnChooser,
  DxSearchPanel,
  DxHeaderFilter,
  DxFilterRow,
  DxPaging,
  DxPager,
  DxColumn,
} from 'devextreme-vue/data-grid';
export default {
  components: {
    TheHeader,
    DxDataGrid,
    DxLoadPanel,
    DxColumnChooser,
    DxSearchPanel,
    DxHeaderFilter,
    DxFilterRow,
    DxPaging,
    DxPager,
    DxColumn,
  },
  data() {
    return {
      config: config,
      error: null,
      decline: null,
    };
  },
  methods: {
    async getReceivers(refresh = false) {
      try {
        await this.$store.dispatch('letters/newsletterReceiver', {
          forceRefresh: refresh,
          id: this.$route.params.id,
        });
      } catch (error) {
        this.error = error.message || 'Something went wrong!';
        this.decline = 'Schließen';
      }
    },
    errorMessage() {
      this.error = null;
    },
  },
  computed: {
    receivers() {
      return this.$store.getters['letters/receivers'];
    },
  },
  created() {
    loadMessages(deMessages);
    locale(navigator.language);
    this.getReceivers(true);
  },
};
</script>

<style scoped>
@media (min-width: 420px) {
  .page-title {
    top: 4.4rem;
  }
}
@media (min-width: 520px) {
  h1 {
    font-size: 2rem;
  }
  .grid-title {
    top: 4.7rem;
  }
}
@media (min-width: 992px) {
  .grid-title {
    top: 4.8rem;
  }
}
</style>