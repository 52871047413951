<template>
  <header class="page-title">
    <nav class="navbar navbar-expand-lg navbar-light">
      <div class="">
        <div class="custom-menu-top">
          <a href="http://weiterer.de/" target="_blank" class="">
            <img class="img-fluid" src="../../../public/logo.jpg" />
          </a>
        </div>
        <div class="container-fluid">
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#mainmenu"
            aria-controls="mainmenu"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="mainmenu">
            <div v-if="getToken.token">
              <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                <li class="nav-item">
                  <router-link class="nav-link" to="/newsletters"
                    >Newsletter</router-link
                  >
                </li>
                <li class="nav-item">
                  <router-link class="nav-link" to="/edit-template"
                    >Template bearbeiten</router-link
                  >
                </li>
                <li class="nav-item">
                  <router-link class="nav-link" to="/subscribers"
                    >Abonnenten</router-link
                  >
                </li>
                <li class="nav-item">
                  <router-link class="nav-link" @click="logout()" to="/"
                    >Abmelden</router-link
                  >
                </li>
              </ul>
            </div>
            <div v-else>
              <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                <li class="nav-item">
                  <router-link class="nav-link" to="/subscribe"
                    >Abbonieren</router-link
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </header>
  <hr />
</template>

<script>
export default {
  data() {
    return {
      windowWidth: window.innerWidth,
      menuVisible: false,
    };
  },
  methods: {
    logout() {
      this.$store.dispatch('auth/logout');
    },
    toggleMenu() {
      this.menuVisible = !this.menuVisible;
    },
  },
  computed: {
    getToken() {
      return this.$store.getters['auth/token'];
    },
    maxwidth922() {
      if (this.windowWidth <= 992) {
        // this.$set(this.screen_size, "width", window.innerWidth);
        return true;
      }
      return false;
    },
  },
  mounted() {
    window.onresize = () => {
      this.windowWidth = window.innerWidth;
    };
  },
};
</script>

<style scoped>
.custom-menu-top {
  position: relative;
  padding-top: 2.5em;
  padding-bottom: 2.5em;
}
.img-fluid {
  max-width: 50%;
  height: auto;
  display: block;
}
.nav-link {
  color: #777;
  padding: 0.5em 1em;
}
.nav-link:hover {
  background-color: #eee;
  text-decoration: none;
  color: #146502 !important;
}
.router-link-active {
  color: #146502 !important;
}

.page-title {
  padding-left: 1rem;
}
header {
  width: 100%;
}

li {
  margin: 0 2rem;
}
</style>