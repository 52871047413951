import { createRouter, createWebHashHistory } from 'vue-router';

import ListNewsletters from './pages/newsletters/ListNewsletters.vue';
import NewsletterReceiver from './pages/newsletters/NewsletterReceiver.vue';
import ListSubscribers from './pages/subscribers/ListSubscribers.vue';
import UserSubscribe from './pages/subscribers/UserSubscribe.vue';
import VerifySubscriber from './pages/subscribers/VerifySubscriber.vue';
import UserUnsubscribe from './pages/subscribers/UserUnsubscribe.vue';
import EditTemplates from './pages/templates/EditTemplates.vue';
import LoginAdmin from './pages/admin/LoginAdmin.vue';


import store from './store/index.js';

const router = createRouter({
  history: createWebHashHistory('/'),
  routes: [
    { path: '/', redirect: '/subscribe' },
    { path: '/subscribe', component: UserSubscribe },
    { path: '/verify-email', component: VerifySubscriber },
    { path: '/unsubscribe/:subguid', component: UserUnsubscribe, props: true },
    { path: '/login', component: LoginAdmin },
    { path: '/newsletters', component: ListNewsletters, meta: { requiresAuth: true } },
    { path: '/newsletters/:id/receivers', component: NewsletterReceiver, meta: { requiresAuth: true } },
    { path: '/edit-template', component: EditTemplates, meta: { requiresAuth: true } },
    { path: '/subscribers', component: ListSubscribers, meta: { requiresAuth: true } },
  ],
});
router.beforeEach(function (to, _, next) {
  const auth = store.getters["auth/isAuthenticated"];
  if (to.meta.requiresAuth && !auth) {
    next('/subscribe');
  }
  else {
    next();
  }
});

let logoutTimer
router.afterEach(function () {
  if (store.getters['auth/token'].token) {
    if (Number(Date.now()) > +Number(localStorage.getItem('loginTime')) + Number(localStorage.getItem('expiresTime'))) {
      store.dispatch('auth/logout');
    } else {
      clearTimeout(logoutTimer);
      logoutTimer = setTimeout(
        function () { store.dispatch('auth/logout') },
        localStorage.getItem('expiresTime')
      );
    }
  }
});
export default router;

