<template>
  <error-message :show="!!error" title="Fehlermeldung" @close="errorMessage">
    <p>{{ error }}</p>
  </error-message>
  <custom-message :show="!!message" @close="handleMessage" :decline="decline">
    <template v-slot:header>
      <h2>{{ title }}</h2>
    </template>
    <p>{{ message }}</p>
  </custom-message>
  <div>
    <form style="padding: 1rem" @submit.prevent="registerSubscribe">
      <fieldset>
        <div class="mb-3">
          <label class="form-label">Anrede</label>
          <select class="form-control data-field" v-model="selected">
            <option
              v-for="salutation in salutations"
              :key="salutation.salutationId"
              :value="salutation.salutationId"
            >
              {{ salutation.short }}
            </option>
          </select>
        </div>
        <div class="mb-3">
          <label class="form-label">Vorname*</label>
          <input
            class="form-control data-field"
            type="text"
            v-model="firstName"
            required
          />
        </div>
        <div class="mb-3">
          <label class="form-label">Nachname*</label>
          <input
            class="form-control data-field"
            type="text"
            v-model="lastName"
            required
          />
        </div>
        <div class="mb-3">
          <label class="form-label">E-Mail Adresse*</label>
          <input
            class="form-control data-field"
            type="email"
            placeholder="example@test.com"
            v-model="email"
            required
          />
        </div>
        <div class="mb-3">
          <label class="form-label">
            <a
              href="https://weiterer.de/agb/"
              target="_blank"
              rel="noopener noreferrer"
              >AGB</a
            >
            und
            <a
              href="https://weiterer.de/disclaimer/"
              target="_blank"
              rel="noopener noreferrer"
              >datenschutzrechtliche Einwilligung</a
            >
            akzeptieren*</label
          >
          <div>
            <input class="form-check-input" type="checkbox" v-model="accept" />
          </div>
        </div>
        <div class="mb-3">
          <vue-recaptcha
            ref="recaptcha"
            sitekey="6Lc7bkUUAAAAAH5oCI8OyFB5XoJZYIg5YRkYnBYu"
            v-model="recaptcha"
            @verify="verifyRecaptcha($event)"
            @expired="expiredRecaptcha"
          />
        </div>
        <div class="mb-3">
          <button
            class="btn button-control"
            :disabled="
              !selected ||
              !firstName ||
              !lastName ||
              !email ||
              !accept ||
              !recaptcha
            "
          >
            Newsletter abonnieren
          </button>
        </div>
        <div class="mb-3">
          <p class="required mt-3">* Diese Felder müssen ausgefüllt werden.</p>
        </div>
      </fieldset>
    </form>
  </div>
</template>

<script>
import { VueRecaptcha } from 'vue-recaptcha';
export default {
  components: { VueRecaptcha },
  data() {
    return {
      error: null,
      message: null,
      decline: null,
      selected: null,
      salutations: null,
      accept: null,
      email: null,
      firstName: null,
      lastName: null,
      recaptcha: null,
    };
  },
  methods: {
    verifyRecaptcha(event) {
      this.recaptcha = event;
    },
    expiredRecaptcha() {
      this.recaptcha = null;
    },
    async registerSubscribe() {
      try {
        await this.$store.dispatch('subs/registerSubscribe', {
          accept: this.accept,
          email: this.email,
          firstName: this.firstName,
          lastName: this.lastName,
          recaptcha: this.recaptcha,
          salutationId: this.selected,
        });
        this.title = 'Registrierung bestätigen';
        (this.message =
          'Bitte bestätigen Sie die Ihnen soeben zugegangene E-Mail durch anklicken auf den Link Email verifizieren um Ihre Anmeldung erfolgreich abzuschließen.'),
          (this.decline = 'Schließen');
        this.$refs.recaptcha.reset();
        this.recaptcha = null;
      } catch (error) {
        this.error = 'Sie sind bereits Angemeldet';
        this.$refs.recaptcha.reset();
        this.recaptcha = null;
      }
    },
    handleMessage() {
      this.message = null;
    },
    errorMessage() {
      this.error = null;
    },
  },
  async created() {
    await this.$store.dispatch('subs/salutations');
    this.salutations = this.$store.getters['subs/salutations'];
  },
};
</script>


<style scoped>
label a {
  color: #0000ee;
  text-decoration-line: underline;
}
label a:visited {
  color: #551a8b;
}
.required {
  font-size: 13px;
}
button,
input,
optgroup,
select,
textarea {
  font: inherit;
  margin: 0;
}
input {
  line-height: normal;
}
</style>