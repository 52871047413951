<template>
  <custom-message
    :show="!!error"
    mode="error"
    title="Fehlermeldung"
    :decline="decline"
    @close="errorMessage"
  >
    <p>{{ error }}</p>
  </custom-message>
  <the-header></the-header>
  <div>
    <h2 class="page-title">Login</h2>
    <form @submit.prevent="login">
      <fieldset>
        <div class="mb-3">
          <label class="form-label">E-Mail</label>
          <input class="form-control data-field" type="email" v-model="email" />
        </div>
        <div class="mb-3">
          <label class="form-label">Passwort</label>
          <input
            class="form-control data-field"
            name="password"
            required
            type="password"
            v-model="password"
          />
        </div>
        <div class="mb-3">
          <button class="btn button-control" type="submit">Anmelden</button>
        </div>
      </fieldset>
    </form>
  </div>
</template>

<script>
import TheHeader from '../../components/layout/TheHeader.vue';
import CustomMessage from '../../components/ui/CustomMessage.vue';
export default {
  components: { TheHeader, CustomMessage },
  data() {
    return {
      email: '',
      password: '',
      error: null,
      decline: null,
    };
  },
  methods: {
    async login() {
      try {
        await this.$store.dispatch('auth/login', {
          email: this.email,
          password: this.password,
        });
      } catch (error) {
        this.error = error.message || 'Something went wrong!';
        this.decline = 'Schließen';
      }
    },
    errorMessage() {
      this.error = null;
    },
  },
};
</script>

<style scoped>
form {
  display: block;
  margin-top: 0em;
  padding: 1rem;
}
</style>