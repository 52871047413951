<template>
  <div>
    <error-message :show="!!error" title="Fehlermeldung" @close="errorMessage">
      <p>{{ error }}</p>
    </error-message>
    <custom-message
      :show="!!message"
      :accept="accept"
      @close="handleMessage"
      @submit="cancel"
    >
      <template v-slot:header>
        <p>{{ title }}</p>
      </template>
    </custom-message>
    <form @submit.prevent novalidate>
      <fieldset>
        <div class="mb-3">
          <label class="form-label">Name des Newsletters</label>
          <input
            class="form-control data-field"
            type="text"
            :readonly="checkIsSent"
            v-model="content.name"
            required
          />
        </div>
        <div class="mb-3">
          <label class="form-label">Name des Absenders</label>
          <input
            class="form-control data-field"
            type="text"
            :readonly="checkIsSent"
            v-model="content.senderName"
            required
          />
        </div>
        <div class="mb-3">
          <label class="form-label">E-Mail Adresse des Absenders</label>
          <input
            class="form-control data-field"
            type="email"
            :readonly="checkIsSent"
            v-model="content.senderEmail"
            required
          />
        </div>
        <div class="mb-3">
          <label class="form-label">Individueller Text</label>
          <textarea
            class="data-field custom-text form-control"
            :readonly="checkIsSent"
            v-model="content.html"
          ></textarea>
        </div>
        <div class="mb-3">
          <label class="form-label">Dateiname des Anhangs</label>
          <div class="input-group">
            <input
              class="form-control data-field file-input"
              type="text"
              :readonly="!file['name']"
              v-model="content.attachmentFile"
            />
            <input
              class="form-control data-field"
              type="file"
              id="selectedFile"
              style="display: none"
              @change="previewFiles"
              ref="myFiles"
            />
            <input
              type="button"
              value="&#128269;"
              v-show="!checkIsSent"
              onclick="document.getElementById('selectedFile').click();"
            />
          </div>
        </div>
        <div class="mb-3">
          <label class="form-label">Veröffentlichungs-Datum</label>
          <input
            class="form-control data-field"
            type="date"
            :readonly="checkIsSent"
            v-model="releaseDate"
            required
          />
        </div>
        <div class="mb-3">
          <label class="form-label">Veröffentlichungs-Uhrzeit</label>
          <input
            class="form-control data-field"
            type="time"
            step="1"
            :readonly="checkIsSent"
            v-model="releaseTimestamp"
            required
          />
        </div>
        <div class="mb-3">
          <label class="form-label">Freigeben</label>
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              :checked="release"
              :readonly="checkIsSent"
              v-model="release"
            />
          </div>
        </div>
        <div class="mb-3" v-if="!checkIsSent">
          <button
            class="btn mr-2 button-control"
            :disabled="
              !content.name ||
              !content.senderName ||
              !content.senderEmail ||
              !releaseDate ||
              !releaseTimestamp ||
              emailValidation()
            "
            @click="triggerSafeProcess"
          >
            Speichern
          </button>

          <button class="btn button-control" @click="cancelSubmit">
            Abbrechen
          </button>
        </div>
        <div class="mb-3" v-else>
          <button class="btn button-control" @click="cancel">Schließen</button>
        </div>
      </fieldset>
    </form>
  </div>
</template>


<script>
export default {
  emits: ['close-edit'],
  props: ['id'],
  data() {
    return {
      error: null,
      file: '',
      releaseAt: '',
      releaseDate: '',
      releaseTimestamp: '',
      content: '',
      isCopy: null,
      message: null,
      title: null,
      accept: null,
      isSent: '',
      release: 0,
    };
  },
  methods: {
    emailValidation() {
      if (
        this.content.senderEmail.includes('@') &&
        this.content.senderEmail.includes('.')
      ) {
        return false;
      }
      return true;
    },
    previewFiles() {
      this.content.attachmentFile = this.$refs.myFiles.files[0]['name'];
      //
      this.file = this.$refs.myFiles.files[0];
    },
    triggerSafeProcess() {
      if (this.isCopy == 1) {
        this.safeCopy();
      } else if (this.isCopy == 0) {
        this.safeEdits();
      } else {
        this.safeNew();
      }
    },
    async safeNew() {
      if (this.content.html == null) {
        this.content.html = '';
      }
      try {
        await this.$store.dispatch('letters/newNewsletter', {
          attachmentFile: this.content.attachmentFile,
          file: this.file,
          html: this.content.html,
          name: this.content.name,
          release: this.release,
          releaseAt: this.releaseDate + ' ' + this.releaseTimestamp,
          releaseDate: this.releaseDate,
          releaseTimestamp: this.releaseTimestamp,
          senderEmail: this.content.senderEmail,
          senderName: this.content.senderName,
        });
        this.cancel();
      } catch (error) {
        this.error = error.message || 'Something wert worng';
      }
    },
    async safeEdits() {
      this.releaseAt = this.releaseDate + ' ' + this.releaseTimestamp;
      try {
        await this.$store.dispatch('letters/editNewsletter', {
          attachmentFile: this.content.attachmentFile,
          file: this.file,
          hasReceivers: this.content.hasReceivers,
          html: this.content.html,
          name: this.content.name,
          newsletterId: this.content.newsletterId,
          release: this.release,
          releaseAt: this.releaseAt,
          releaseAtDate: this.releaseDate,
          releaseAtTimestamp: this.releaseTimestamp,
          senderEmail: this.content.senderEmail,
          senderName: this.content.senderName,
          sent: this.content.sent,
          templateId: this.content.templateId,
        });
        this.cancel();
      } catch (error) {
        this.error = error.message || 'Something went wrong!';
      }
    },
    async safeCopy() {
      this.releaseAt = this.releaseDate + ' ' + this.releaseTimestamp;
      try {
        await this.$store.dispatch('letters/copieNewsletter', {
          attachmentFile: this.content.attachmentFile,
          file: this.file,
          hasReceivers: this.content.hasReceivers,
          html: this.content.html,
          name: this.content.name,
          newsletterId: this.content.newsletterId,
          release: this.release,
          releaseAt: this.releaseAt,
          releaseAtDate: this.releaseDate,
          releaseAtTimestamp: this.releaseTimestamp,
          senderEmail: this.content.senderEmail,
          senderName: this.content.senderName,
          sent: this.content.sent,
          templateId: this.content.templateId,
        });
        this.cancel();
      } catch (error) {
        this.error = error.message || 'Something went wrong!';
      }
    },
    cancel() {
      this.$emit('close-edit', false);
      return this.$store.dispatch('letters/getNewsletters');
    },
    cancelSubmit() {
      this.accept = 'Ja';
      this.title = 'Wirklich abbrechen?';
      this.message = 'Möchten Sie fortfahren?';
    },
    handleMessage() {
      this.message = null;
    },
    errorMessage() {
      this.error = null;
    },
  },
  computed: {
    checkIsSent() {
      if (this.content.sent == 'Ja' && this.isCopy == 0) {
        return true;
      } else {
        return false;
      }
    },
  },
  created() {
    this.isCopy = this.$store.getters['letters/callCopyNewsletter'];
    if (this.isCopy == 0 || this.isCopy == 1) {
      this.content = this.$store.getters['letters/callEditNewsletter'];
      const releaseAt = this.content.releaseAt.split(' ');
      this.releaseDate = releaseAt[0];
      this.releaseTimestamp = releaseAt[1];
      if (this.content.release == 'Ja') {
        this.release = true;
      } else {
        this.release = false;
      }
    } else {
      this.content = {
        newsletterId: null,
        templateId: null,
        name: null,
        senderName: null,
        senderEmail: null,
        html: null,
        attachmentFile: null,
        release: 0,
        sent: 0,
        hasReceivers: null,
      };
      const today = new Date().toLocaleDateString().split('.');
      if (today[0].length == 1) {
        today[0] = 0 + today[0];
      }
      if (today[1].length == 1) {
        today[1] = 0 + today[1];
      }
      const date = today[2] + '-' + today[1] + '-' + today[0];
      this.releaseDate = date;
      this.releaseTimestamp = new Date().toLocaleTimeString();
    }
    if (this.isCopy == 1) {
      this.release = false;
    }
  },
};
</script>

<style scoped>
form {
  display: block;
  margin-top: 0em;
  padding: 1rem;
}

fieldset {
  margin: 0;
  padding: 0.35rem 0 0.75rem;
  border: 0;
}
.custom-text {
  height: 100px;
}
.file-input {
  width: 300px;
}
@media (min-width: 655px) {
  .custom-text {
    width: 100%;
    min-width: 600px;
  }
  .file-input {
    max-width: 600px;
    width: 600px;
  }
}
.mr-2 {
  margin-right: 0.5rem;
}
</style>