import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import { createApp } from 'vue';



import router from './router.js';
import store from './store/index.js';
import App from './App.vue';
import CustomMessage from './components/ui/CustomMessage.vue';
import ErrorMessage from './components/ui/ErrorMessage.vue';


const app = createApp(App);


app.use(router);
app.use(store);
app.component('custom-message', CustomMessage);
app.component('error-message', ErrorMessage);

app.mount('#app');