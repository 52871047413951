<template>
  <main>
    <router-view></router-view>
  </main>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';

export default {
  data() {
    return {
      authenticated: true,
    };
  },
  computed: {
    isAuth() {
      return this.$store.getters['auth/isAuthenticated'];
    },
  },
};
</script>


<style>
* {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 400;
  font-size: 17px;
}
body {
  margin: 0;
}
h1 {
  margin: 0;
}
.router-link-active {
  font-weight: bold;
}

table a {
  color: #146502 !important;
}
a {
  cursor: pointer;
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}
.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}
.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}
.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}
.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}
.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}
.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}
.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}
.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}
.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}
.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

/* ------------------------------------------------------------------------------ */
.data-field {
  padding: 0.5rem 0.6rem;
  border: 1px solid #ccc;
  box-shadow: inset 0 1px 3px #ddd;
  border-radius: 4px;
  vertical-align: middle;
  box-sizing: border-box;
}

.form-control {
  width: 100%;
  max-width: 300px;
}

.form-control:focus {
  border-color: #86b7fe;
}
.form-control:focus:invalid {
  color: #b94a48;
  border-color: #e9322d;
}

.btn:focus,
.form-control:focus,
.form-check-input:focus {
  outline: 0;
  box-shadow: none;
}
.page-title {
  font-weight: 700;
  padding-left: 1rem;
  font-family: Raleway, sans-serif;
}
.grid-title {
  font-weight: 700;
  position: relative;
  z-index: 1;
  padding-left: 1rem;
  width: 200px;
  top: 1.5rem;
  font-family: Raleway, sans-serif;
}
.button-control {
  font-family: inherit;
  font-size: 100%;
  padding: 0.5rem 1rem;
  color: rgba(0, 0, 0, 0.8);
  border: transparent;
  background-color: #e6e6e6;
  text-decoration: none;
  border-radius: 2px;
  display: inline-block;
  zoom: 1;
  white-space: nowrap;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
}
.button-control:hover {
  background-image: linear-gradient(
    transparent,
    rgba(0, 0, 0, 0.05) 40%,
    rgba(0, 0, 0, 0.1)
  );
}
.dx-datagrid-header-panel .dx-toolbar {
  margin: 2rem 0 3rem 0 !important;
}
.dx-datagrid-search-panel {
  width: 100px;
}

@media (min-width: 520px) {
  .dx-datagrid-search-panel {
    width: 300px !important;
  }
}
@media (min-width: 992px) {
  .dx-datagrid-search-panel {
    width: 500px !important;
  }
}
</style>