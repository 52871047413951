<template>
  <the-header></the-header>
  <custom-message
    :show="!!error"
    mode="error"
    title="Fehlermeldung"
    :decline="decline"
    @close="handleError"
  >
    <p>{{ error }}</p>
  </custom-message>
  <custom-message
    :show="!!message"
    :accept="accept"
    :decline="decline"
    @close="handleMessage"
    @submit="deleteSubscriber"
  >
    <template v-slot:header>
      <p>{{ title }}</p>
    </template>
  </custom-message>
  <div>
    <div id="app-container">
      <h3 class="grid-title">Abonnenten</h3>
      <DxDataGrid
        id="dataGrid"
        :data-source="subscribers"
        key-expr="subscriberId"
        :show-borders="config.Datagrid.showBorders"
        :allowColumnReordering="config.Datagrid.allowColumnReordering"
        :allowColumnResizing="config.Datagrid.allowColumnResizing"
        :columnAutoWidth="config.Datagrid.columnAutoWidth"
        :column-hiding-enabled="config.Datagrid.columnHidingEnabled"
        :focused-row-enabled="config.Datagrid.focusedRowEnabled"
        :selection="config.Datagrid.selection"
        @selection-changed="onSelectionChanged"
      >
        <DxPaging :page-size="config.Paging.pageSize" />
        <DxPager
          :visible="config.Pager.visible"
          :display-mode="config.Pager.displayMode"
          :show-page-size-selector="config.Pager.showPageSizeSelector"
          :show-navigation-buttons="config.Pager.showNavigationButtons"
        />
        <DxColumnChooser
          :enabled="config.ColumnChooser.enabled"
          :mode="config.ColumnChooser.mode"
          :allowSearch="config.ColumnChooser.allowSearch"
          :width="config.ColumnChooser.width"
          :height="config.ColumnChooser.height"
        />
        <DxColumn type="buttons">
          <DxButton
            icon="trash"
            text="Löschen"
            type="normal"
            styling-mode="contained"
            @click="onClick($event)"
          />
        </DxColumn>
        <DxColumn data-type="string" data-field="subscriberId" caption="ID" />
        <DxColumn data-field="salutation" caption="Anrede" />
        <DxColumn data-field="firstName" caption="Vorname" />
        <DxColumn
          data-field="email"
          caption="E-Mail Adresse"
          cell-template="emailLink"
        />
        <DxColumn data-field="unsubReason" caption="Grund für Abmeldung" />
        <DxColumn
          data-field="subscribedAt"
          data-type="date"
          format="dd.MM.yyyy HH:mm:ss"
          caption="Angemeldet"
        />
        <DxColumn data-field="verified" caption="Bestätigt" />
        <DxColumn data-field="archived" caption="Abonniert" />
        <template #emailLink="{ data }">
          <a :href="'mailto:' + data.value">{{ data.value }}</a>
        </template>
        <DxSearchPanel :visible="config.SearchPanel.visible" />
        <DxLoadPanel :enabled="config.LoadPanel.enabled" />
        <DxHeaderFilter :visible="config.HeaderFilter.enabled" />
        <DxFilterRow :visible="config.FilterRow.visible" />
      </DxDataGrid>
    </div>
  </div>
</template>

<script>
import deMessages from '../../locales/de.json';
import { locale, loadMessages } from 'devextreme/localization';
import TheHeader from '../../components/layout/TheHeader.vue';
import CustomMessage from '../../components/ui/CustomMessage.vue';
import config from '../../config/Datagrid.json';
import {
  DxDataGrid,
  DxLoadPanel,
  DxSearchPanel,
  DxHeaderFilter,
  DxColumnChooser,
  DxPaging,
  DxPager,
  DxFilterRow,
  DxColumn,
  DxButton,
} from 'devextreme-vue/data-grid';

export default {
  components: {
    TheHeader,
    CustomMessage,
    DxDataGrid,
    DxLoadPanel,
    DxColumnChooser,
    DxSearchPanel,
    DxHeaderFilter,
    DxPaging,
    DxPager,
    DxFilterRow,
    DxColumn,
    DxButton,
  },
  data() {
    return {
      config: config,
      error: null,
      decline: null,
      isLoading: false,
      accept: null,
      message: null,
      title: null,
      selectedId: null,
    };
  },
  created() {
    loadMessages(deMessages);
    locale(navigator.language);
    this.getSubscribers(true);
  },
  methods: {
    onClick(selectedRowsData) {
      //speichert die ID der selektierten Spalte ab
      this.selectedId = selectedRowsData.row.key;

      this.title =
        'Den Abonnenten "' +
        selectedRowsData.row.data.firstName +
        ' ' +
        selectedRowsData.row.data.lastName +
        '" wirklich löschen?';
      this.accept = 'Ja';
      this.decline = 'Schließen';
      this.message = 'Möchten Sie fortfahren?';
    },
    async deleteSubscriber() {
      this.message = null;
      try {
        await this.$store.dispatch('subs/deleteSubscriber', {
          id: this.selectedId,
        });
      } catch (error) {
        this.error = error.message || 'Something went wrong!';
        this.decline = 'Schließen';
      }
      this.getSubscribers(true);
    },
    async getSubscribers(refresh = false) {
      this.isLoading = true;
      try {
        await this.$store.dispatch('subs/getSubscribers', {
          forceRefresh: refresh,
        });
      } catch (error) {
        this.error = error.message || 'Something went wrong!';
        this.decline = 'Schließen';
      }
      this.isLoading = false;
    },
    onSelectionChanged({ selectedRowsData }) {
      this.selectedId = selectedRowsData[0].subscriberId;
    },
    handleError() {
      this.error = null;
    },
    handleMessage() {
      this.message = null;
    },
  },
  computed: {
    subscribers() {
      return this.$store.getters['subs/subscribers'];
    },
  },
};
</script>

<style scoped>
@media (min-width: 420px) {
  .page-title {
    top: 4.4rem;
  }
}
@media (min-width: 520px) {
  h1 {
    font-size: 2rem;
  }
  .grid-title {
    top: 4.7rem;
  }
}
@media (min-width: 992px) {
  .grid-title {
    top: 4.8rem;
  }
}
</style>