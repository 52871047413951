import api_link from '../../config/variables.js';

export default {
  namespaced: true,
  state() {
    return {
      templates: null,
      safeTemplates: null,
    };
  },
  mutations: {
    getTemplates(state, payload) {
      state.templates = payload;
    },
    safeTemplates(state, payload) {
      state.safeTemplates = payload
    }
  },
  actions: {
    async getTemplates(context) {
      const token = this.getters["auth/token"];
      const templates = await fetch(api_link + '/templates/1', {
        method: 'GET',
        headers: {
          'Authorization': 'Bearer ' + token.token,
          'Content-Type': 'application/json',
        },
      });
      const responseTemplates = await templates.json();
      
      if (!templates.ok) {
        const error = new Error(responseTemplates.message || 'Templates konnten nicht geladen werden!');
        throw error;
      }
      
      const temps = responseTemplates;

      context.commit('getTemplates', temps);
    },
    async safeTemplates(context, payload) {
      const token = this.getters["auth/token"];
      const templates = await fetch(api_link + '/templates/1', {
        method: 'PUT',
        headers: {
          'Authorization': 'Bearer ' + token.token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: "Template",
          html: payload.html,
          unsubText: payload.unsubText,
          unsubUrl: payload.unsubUrl
        })
      });

      if (!templates.ok) {
        //Da bei einer erfolgreichen Übertragung von (safeTemplate) kein Response zurück kommt, kann nur bei einer Fehlermeldung der Response ausgewertet werden
        const responseTemplates = await templates.json();
        const error = new Error(responseTemplates.message || 'Template konnte nicht gespeichert werden!');
        throw error;
      }

      if (templates['status'] === 204) {
        const message = {
          title: 'Gespeichert',
          text: 'Template gespeichert'
        };
        context.commit('safeTemplates', message);
      }
    }
  },
  getters: {
    templates(state) {
      return state.templates;
    },
    safeTemplates(state) {
      return state.safeTemplates;
    },
  }
}