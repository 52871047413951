import router from '../../router.js';
import api_link from '../../config/variables.js';

// const timeOutInMs = 4 * 60 * 60 * 1000; // h*m*s*ms
// let timer

export default {
  namespaced: true,
  state() {
    return {
      token: null,
      timer: null
    };
  },
  mutations: {
    timer(state, payload) {
      state.timer = payload
    },
    setUser(state, payload) {
      state.token = payload.token;
    },
    logout(state) {
      state.token = null;
      router.push('/login');
    },
  },
  actions: {
    logout(context) {
      localStorage.clear();
      context.commit('logout');
    },
    async login(context, payload) {
      //await führt den folgenden Code erst aus, wenn der Aufruf erfolgt und eine Antwort angekommen ist.
      const response = await fetch(api_link + '/authenticate', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: payload.email,
          password: payload.password
        }),
      });

      const responseLogin = await response.json();

      if (!response.ok) {
        const error = new Error(responseLogin.message || 'Anmeldung fehlgeschlagen!');
        throw error;
      }

      const expiresIn = +(responseLogin.exp - responseLogin.iat) * 1000;

      localStorage.setItem('expiresTime', expiresIn)
      localStorage.setItem('loginTime', Number(Date.now()))

      context.commit('setUser', {
        token: responseLogin.token
      });
      if (responseLogin['token'] != null) {
        router.push('/newsletters');
      }
    },
  },

  getters: {
    timer(state) {
      return state.timer
    },
    token(state) {
      // return state.token;
      return { token: state.token };
    },
    isAuthenticated(state) {
      return !!state.token;
    },
  }
};