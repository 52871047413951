<template>
  <teleport to="body">
    <div v-if="show" @click="tryClose" class="backdrop"></div>
    <dialog open v-if="show" :class="mode">
      <header>
        <slot name="header">
          <h2>{{ title }}</h2>
        </slot>
      </header>
      <section>
        <slot></slot>
      </section>
      <form @submit.prevent>
        <section>
          <div>
            <input class="form-control data-field" type="email" v-model="email" required />
          </div>
        </section>
        <menu v-if="!fixed">
          <slot name="accept" v-if="!!accept">
            <button class="btn button-control" @click="trySubmit">
              {{ accept }}
            </button>
          </slot>
          <slot name="actions">
            <button
              class="btn button-control ms-2"
              ref="userButton"
              @click="tryClose"
            >
              Abbrechen
            </button>
          </slot>
        </menu>
      </form>
    </dialog>
  </teleport>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';
export default {
  props: {
    mode: {
      type: String,
      required: false,
      default: null,
    },
    show: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: false,
    },
    fixed: {
      type: Boolean,
      required: false,
      default: false,
    },
    accept: {
      type: String,
      required: false,
    },
  },
  emits: ['close', 'submit'],
  data() {
    return {
      email: '',
    };
  },
  methods: {
    tryClose() {
      if (this.fixed) {
        return;
      }
      this.$emit('close');
    },
    trySubmit() {
      if (this.fixed) {
        return;
      }
      this.$emit('submit', this.email);
    },
  },
};
</script>

<style scoped>
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 10;
}

dialog {
  position: fixed;
  top: 20vh;
  left: 10%;
  width: 80%;
  z-index: 100;
  border-radius: 12px;
  border: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  padding: 0;
  margin: 0;
  overflow: hidden;
  background-color: white;
}
.error header {
  background-color: #e44724;
}

header {
  background-color: #e6e6e6;
  color: #000;
  width: 100%;
  padding: 1rem;
}

header h2 {
  margin: 0;
}

section {
  padding: 1rem;
}

menu {
  padding: 1rem;
  display: flex;
  justify-content: flex-end;
  margin: 0;
}

button {
  width: 115px;
}

input:focus,
input:active {
  border-color: #86b7fe;
}

input:focus:invalid,
input:active:invalid {
  color: #b94a48;
  border-color: #e9322d;
}

@media (min-width: 768px) {
  dialog {
    left: calc(50% - 20rem);
    width: 40rem;
  }
}
</style>