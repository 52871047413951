<template>
  <custom-message
    :show="!!error"
    mode="error"
    title="Fehlermeldung"
    :decline="decline"
    @close="handleError"
  >
    <p>{{ error }}</p>
  </custom-message>
  <custom-message
    :show="!!message"
    :accept="accept"
    :decline="decline"
    @close="handleMessage"
    @submit="triggerProcess"
  >
    <template v-slot:header>
      <p>{{ title }}</p>
    </template>
  </custom-message>

  <selected-test-custom-message
    :show="!!messageSelectedTest"
    :accept="acceptSelectedTest"
    @close="handleMessageSelectedTest"
    @submit="sendTestEmail"
  >
    <template v-slot:header>
      <p>{{ titleSelectedTest }}</p>
    </template>
  </selected-test-custom-message>

  <the-header></the-header>
  <div>
    <edit-newsletter
      v-if="openEditNewsletter"
      :key="callEditNewsletters"
      :id="callEditNewsletters"
      @close-edit="closeEditNewsletter"
    ></edit-newsletter>
    <h3 class="grid-title">Newsletter</h3>
    <div id="app-container">
      <DxDataGrid
        id="dataGrid"
        :data-source="newsletters"
        key-expr="newsletterId"
        :show-borders="config.Datagrid.showBorders"
        :allowColumnReordering="config.Datagrid.allowColumnReordering"
        :allowColumnResizing="config.Datagrid.allowColumnResizing"
        :columnAutoWidth="config.Datagrid.columnAutoWidth"
        :column-hiding-enabled="config.Datagrid.columnHidingEnabled"
        :focused-row-enabled="config.Datagrid.focusedRowEnabled"
        :selection="config.Datagrid.selection"
        @selection-changed="onSelectionChanged"
      >
        <DxPaging :page-size="config.Paging.pageSize" />
        <DxPager
          :visible="config.Pager.visible"
          :display-mode="config.Pager.displayMode"
          :show-page-size-selector="config.Pager.showPageSizeSelector"
          :show-navigation-buttons="config.Pager.showNavigationButtons"
        />
        <DxColumnChooser
          :enabled="config.ColumnChooser.enabled"
          :mode="config.ColumnChooser.mode"
          :allowSearch="config.ColumnChooser.allowSearch"
          :width="config.ColumnChooser.width"
          :height="config.ColumnChooser.height"
        />
        <DxSelection
          :mode="config.Selection.mode"
          :showCheckBoxesMode="config.Selection.showCheckBoxesMode"
        />
        <DxColumn type="buttons">
          <DxButton
            icon="edit"
            text="Ändern"
            type="normal"
            :disabled="isEditVisisble"
            styling-mode="contained"
            @click="onEdit($event)"
          />
          <DxButton
            icon="fullscreen"
            text="Ansehen"
            type="normal"
            :disabled="isShowVisisble"
            styling-mode="contained"
            @click="onEdit($event)"
          />
          <DxButton
            icon="copy"
            text="Kopieren"
            type="normal"
            styling-mode="contained"
            @click="copyNewsletter($event)"
          />
          <DxButton
            icon="trash"
            text="Löschen"
            type="normal"
            styling-mode="contained"
            @click="onDelete($event)"
          />
          <DxButton
            icon="group"
            text="Empfänger"
            type="normal"
            :disabled="isShowVisisble"
            styling-mode="contained"
            @click="routeReceiver($event)"
          />
        </DxColumn>
        <DxColumn data-type="string" data-field="newsletterId" caption="ID" />
        <DxColumn data-field="name" caption="Name des Newsletters" />
        <DxColumn data-field="senderName" caption="Name des Abesenders" />
        <DxColumn
          data-field="senderEmail"
          caption="E-Mail Adresse des Absenders"
          cell-template="emailLink"
        />
        <DxColumn
          data-field="attachmentFile"
          caption="Anhang"
          cell-template="attachmentFile"
        />
        <DxColumn
          data-field="releaseAt"
          data-type="date"
          format="dd.MM.yyyy HH:mm:ss"
          caption="Veröffentlichung"
        />
        <DxColumn data-field="release" caption="Freigegeben" />
        <DxColumn data-field="sent" caption="Gesendet" />
        <template #attachmentFile="{ data }">
          <a
            @click="
              downloadFile(data.data.newsletterId, data.data.attachmentFile)
            "
            >{{ data.value }}</a
          >
        </template>
        <template #emailLink="{ data }">
          <a>{{ data.value }}</a>
        </template>
        <DxSearchPanel :visible="config.SearchPanel.visible" />
        <DxLoadPanel :enabled="config.LoadPanel.enabled" />
        <DxHeaderFilter :visible="config.HeaderFilter.visible" />
        <DxFilterRow :visible="config.FilterRow.visible" />
      </DxDataGrid>
      <div class="p-3">
        <button
          class="btn mr-2 mt-2 button-control"
          @click="addNewsletter($event)"
        >
          Neuen Newsletter anlegen
        </button>
        <button
          class="btn mr-2 mt-2 button-control"
          :disabled="selectedId.length == 0"
          @click="selectedDelete"
        >
          Auswahl löschen
        </button>
        <button
          class="btn mr-2 mt-2 button-control"
          :disabled="selectedId.length == 0 || checkIsSend()"
          @click="selectedSend"
        >
          Auswahl versenden
        </button>
        <button
          class="btn mt-2 button-control"
          :disabled="selectedId.length == 0"
          @click="selectedTest"
        >
          Auswahl testen
        </button>
        <div class="loader" hidden=""></div>
      </div>
    </div>
  </div>
</template>

<script>
import TheHeader from '../../components/layout/TheHeader.vue';
import EditNewsletter from '../../components/newsletters/EditNewsletter.vue';
import CustomMessage from '../../components/ui/CustomMessage.vue';
import SelectedTestCustomMessage from '../../components/ui/SelectedTestCustomMessage.vue';
import deMessages from '../../locales/de.json';
import { locale, loadMessages } from 'devextreme/localization';
import config from '../../config/Datagrid.json';

import {
  DxDataGrid,
  DxPaging,
  DxPager,
  DxLoadPanel,
  DxColumnChooser,
  DxSearchPanel,
  DxHeaderFilter,
  DxFilterRow,
  DxSelection,
  DxColumn,
  DxButton,
} from 'devextreme-vue/data-grid';

export default {
  components: {
    EditNewsletter,
    TheHeader,
    DxDataGrid,
    DxPaging,
    DxPager,
    DxLoadPanel,
    DxColumnChooser,
    DxSearchPanel,
    DxHeaderFilter,
    DxFilterRow,
    DxSelection,
    DxColumn,
    DxButton,
    CustomMessage,
    SelectedTestCustomMessage,
  },
  data() {
    return {
      config: config,
      error: null,
      decline: null,
      openEditNewsletter: false,
      message: null,
      title: null,
      accept: null,
      selectedId: [],
      selectedRow: [],
      cancelMessage: null,
      isDelete: null,
      isSend: null,
      messageSelectedTest: null,
      titleSelectedTest: null,
      acceptSelectedTest: null,
    };
  },

  methods: {
    routeReceiver(selectedRowsData) {
      this.$router.replace(
        '/newsletters/' + selectedRowsData.row.key + '/receivers'
      );
    },
    closeEditNewsletter() {
      this.openEditNewsletter = false;
    },
    async addNewsletter() {
      await this.getNewsletters(true);
      await this.$store.dispatch('letters/parseNewsletterId', {
        id: null,
        isCopy: 2,
      });
      this.openEditNewsletter = true;
    },
    isEditVisisble(selectedRowsData) {
      if (selectedRowsData.row.data.sent == 'Ja') {
        return 1;
      } else {
        return null;
      }
    },
    isShowVisisble(selectedRowsData) {
      if (selectedRowsData.row.data.sent == 'Ja') {
        return null;
      } else {
        return 1;
      }
    },
    async getNewsletters(refresh = false) {
      try {
        await this.$store.dispatch('letters/getNewsletters', {
          forceRefresh: refresh,
        });
      } catch (error) {
        this.decline = 'Schließen';
        this.error = error.message || 'Something went wrong!';
        this.message = null;
      }
    },
    triggerProcess() {
      if (this.isDelete == 1) {
        this.deleteNewsletter();
      } else if (this.isSend == 1) {
        this.sendSelectedNewsletters();
      }
    },
    async onEdit(selectedRowsData) {
      await this.getNewsletters(true);
      await this.$store.dispatch('letters/parseNewsletterId', {
        id: selectedRowsData.row.key,
        isCopy: 0,
      });
      this.openEditNewsletter = true;
    },
    async copyNewsletter(selectedRowsData) {
      await this.getNewsletters(true);
      await this.$store.dispatch('letters/parseNewsletterId', {
        id: selectedRowsData.row.key,
        isCopy: 1,
      });
      this.openEditNewsletter = true;
    },
    onDelete(selectedRowsData) {
      //speichert die ID der selektierten Spalte ab
      this.isDelete = 1;
      this.selectedId = [];
      this.selectedId.push(selectedRowsData.row.key);
      this.decline = 'Nein';

      this.accept = 'Ja';
      this.title =
        'Den Newsletter "' +
        selectedRowsData.row.data.name +
        '" mit der ID ' +
        this.selectedId +
        ' wirklich löschen?';
      this.message = 'Möchten Sie fortfahren?';
    },
    async deleteNewsletter() {
      this.message = null;
      for (const id of this.selectedId) {
        try {
          await this.$store.dispatch('letters/deleteNewsletter', {
            id,
          });
        } catch (error) {
          this.error = error.message || 'Somthing went wrong!';
          this.decline = 'Schließen';
        }
        this.getNewsletters(true);
      }
      this.isDelete = null;
    },
    handleMessage() {
      this.message = null;
    },
    handleError() {
      this.error = null;
    },
    onSelectionChanged({ selectedRowsData }) {
      var i = 0;
      this.selectedId = [];
      this.selectedRow = [];
      while (i < selectedRowsData.length) {
        this.selectedId.push(selectedRowsData[i]['newsletterId']);
        this.selectedRow.push(selectedRowsData[i]);
        i++;
      }
    },
    selectedDelete() {
      this.isDelete = 1;
      this.accept = 'Ja';
      this.decline = 'Nein';
      this.title = this.selectedId.length + ' Newsletter wirklich löschen?';
      this.message = 'Möchten Sie fortfahren?';
    },
    checkIsSend() {
      let res = false;

      for (const newsletter of this.selectedRow) {
        if (newsletter.sent == 'Ja') {
          res = true;
          break;
        }
      }
      return res;
    },
    selectedSend() {
      this.isSend = 1;
      this.accept = 'Ja';
      this.decline = 'Nein';
      this.title =
        'Möchten Sie ' + this.selectedId.length + ' Newsletter versenden?';
      this.message = 'Möchten Sie fortfahren?';
    },
    async sendSelectedNewsletters() {
      this.message = null;
      for (const id of this.selectedId) {
        try {
          await this.$store.dispatch('letters/selectedSendNewsletters', {
            id,
          });
        } catch (error) {
          this.error = error.message || 'Somthing went wrong!';
        }
        this.getNewsletters(true);
      }
      this.isSend = null;
    },
    async sendTestEmail(test) {
      this.messageSelectedTest = null;
      for (const id of this.selectedId) {
        try {
          await this.$store.dispatch('letters/selectedSendTestNewsletters', {
            id,
            email: test,
          });
        } catch (error) {
          this.error = error.message || 'Something went wrong!';
        }
      }
    },
    selectedTest() {
      this.acceptSelectedTest = 'Ok';
      this.titleSelectedTest = 'Ziel E-Mail Adresse angeben';
      this.messageSelectedTest = 'Möchten Sie fortfahren?';
    },
    handleMessageSelectedTest() {
      this.messageSelectedTest = null;
    },
    showDownloadFile(selectedRowsData) {
      if (selectedRowsData.row.data.attachmentFile != null) {
        return true;
      }
    },
    async downloadFile(newsletterId, name) {
      try {
        await this.$store.dispatch('letters/downloadFile', {
          id: newsletterId,
          attachmentFile: name,
        });
      } catch (error) {
        this.error = error.message || 'Something went wrong!';
        this.decline = 'Schließen';
      }
    },
  },
  computed: {
    newsletters() {
      return this.$store.getters['letters/newsletters'];
    },
    callEditNewsletters() {
      return this.$store.getters['letters/callEditNewsletter'];
    },
  },
  created() {
    loadMessages(deMessages);
    locale(navigator.language);
  },
  beforeMount() {
    this.getNewsletters();
  },
};
</script>

<style scoped>
@media (min-width: 420px) {
  .page-title {
    top: 4.4rem;
  }
}
@media (min-width: 520px) {
  h1 {
    font-size: 2rem;
  }
  .grid-title {
    top: 4.7rem;
  }
}
@media (min-width: 992px) {
  .grid-title {
    top: 4.8rem;
  }
}
</style>
